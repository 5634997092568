import React, { type FC } from 'react';
import { SpotlightPulse, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { token, useThemeObserver } from '@atlaskit/tokens';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import type { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import IssueViewStatisticsOnboarding from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/assets/issue-view-statistics-onboarding.gif';
import IssueViewStatisticsOnboardingDarkMode from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/assets/issue-view-statistics-onboarding-dark-mode.gif';
import { useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ONBOARDING_ENGAGEMENT_ID } from './constants.tsx';
import messages from './messages.tsx';

export interface NinStatisticsSpotlightProps {
	shouldShowSpotlight: boolean;
	coordinationClient: CoordinationClient;
	onStatisticsTryNow: () => void;
	onStatisticsOnboardingStop: () => void;
	children?: React.ReactNode;
}

export const StatisticsOnboarding: FC<NinStatisticsSpotlightProps> = ({
	shouldShowSpotlight,
	coordinationClient,
	onStatisticsTryNow,
	onStatisticsOnboardingStop,
	children,
}) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();

	if (!shouldShowSpotlight) {
		return <>{children}</>;
	}

	return (
		<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
			<SpotlightTarget name="nin-meatball-button-statistics-spotlight">
				<SpotlightPulse radius={3} pulse={shouldShowSpotlight}>
					{children}
				</SpotlightPulse>
			</SpotlightTarget>
			<SpotlightTransition>
				<JiraSpotlight
					heading={formatMessage(messages.getWorkInsights)}
					messageId={ONBOARDING_ENGAGEMENT_ID}
					image={
						colorMode === 'dark'
							? IssueViewStatisticsOnboardingDarkMode
							: IssueViewStatisticsOnboarding
					}
					key="nin-meatball-button-statistics-spotlight"
					actions={[
						{
							onClick: onStatisticsTryNow,
							text: formatMessage(messages.tryItOut),
						},
						{
							onClick: onStatisticsOnboardingStop,
							text: formatMessage(messages.dismiss),
						},
					]}
					targetRadius={3}
					targetBgColor={token('color.background.discovery')}
					target="nin-meatball-button-statistics-spotlight"
					messageType="engagement"
					dialogPlacement="bottom right"
				>
					{expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? formatMessage(messages.onboardingStatisticsDescriptionIssueTermRefresh)
						: formatMessage(messages.onboardingStatisticsDescription)}
				</JiraSpotlight>
			</SpotlightTransition>
		</ChoreographerContextProvider>
	);
};
